import { notify } from 'modules/yoio/errorsService';
import { default as ErrorView } from 'next/error'
import md5 from 'md5'

export default function CustomErrorPage({ statusCode }) {
  return (
    <>
        <ErrorView statusCode={statusCode} />
    </>
  )
}

export async function getServerSideProps(context) {

  const { res, err } = context

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  const error = err || new Error('Unknown error caught by CustomErrorPage.getServerSideProps.')

  const forwarded = context.req.headers["x-forwarded-for"]
  const ip = forwarded ? forwarded.split(/, /)[0] : context.req.connection?.remoteAddress

  // Ignore 404 errors
  if (statusCode === 404) {
    return { 
        props: {
            statusCode: 404
        } 
    }
  }

  const notifyOptions = {}
  if (error.request && !error.response) {
    notifyOptions.disableLogging = true
  }

  notify(error, event=>{
    event.addMetadata('request', {
      userAgent: context?.req?.headers?.['user-agent'],
      host: context?.req?.headers?.host,
      path: context?.req?.url,
      ip
    })
    if (context?.req?.url) {
      event.groupingHash = md5(context?.req?.url) + '_' + (event.groupingHash || '')
    }
  }, notifyOptions)

  return { 
        props: {
            statusCode
        } 
    }
}